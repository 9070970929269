import * as changesets from 'json-diff-ts';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import ClauseComponentApi from '../Actions/API';
import {
  GETCLAUSEDATABYTYPE,
  GetClauseDataByType,
  POSTCLAUSEDATABYTYPE,
  PostClauseDataByType,
} from '../Actions/def';
import ClauseComponentActionGenerator from '../Actions/Gen';
import { clauseTypeData } from '../State/clauseComponentState';
import { getEditedPayload } from '../utils/ClauseTypeUtils';

function* getClauseDataByType(action: GetClauseDataByType): any {
  const fileID = action.payload.fileID;
  const type = action.payload.type;
  try {
    const response = yield call(
      ClauseComponentApi.getClausesByType,
      fileID,
      type
    );
    if (response) {
      const data = JSON.parse(JSON.stringify(response as clauseTypeData));
      yield put(
        ClauseComponentActionGenerator.getClausDataByTypeSuccess(data, type)
      );
      yield put(ClauseComponentActionGenerator.UpdateClauseData(data, type));
    } else {
      yield put(ClauseComponentActionGenerator.getClausDataByTypeFailure());
    }
  } catch (e) {
    yield put(ClauseComponentActionGenerator.getClausDataByTypeFailure());
  }
}

function* postClauseDataByType(action: PostClauseDataByType): any {
  const fileID = action.payload.fileID;
  const type = action.payload.type;
  const diff = action.payload.diff;
  const updatedClauseData = action.payload.updatedClauseData;
  const flatChanges = changesets.flattenChangeset(diff);
  const editedPayload = getEditedPayload(flatChanges);
  let updated_payload: any = {
    edited_content: editedPayload?.edited_content,
    copy_edited_content: updatedClauseData,
  };

  if (type === 'term') {
    let end_date = null;
    if (updatedClauseData?.end?.length > 0) {
      end_date = updatedClauseData?.end?.[0]?.normalized_date;
    }
    updated_payload = { ...updated_payload, end_date: end_date };
  }

  try {
    const response = yield call(
      ClauseComponentApi.postClauseDataByType,
      fileID,
      type,
      updated_payload
    );

    if (response) {
      yield put(
        ClauseComponentActionGenerator.postClauseDataByTypeSuccess(
          type,
          updatedClauseData
        )
      );
    } else {
      yield put(ClauseComponentActionGenerator.postClauseDataByTypeFailure());
    }
  } catch (e) {
    yield put(ClauseComponentActionGenerator.postClauseDataByTypeFailure());
  }
}

export default function* ClauseComponentWatcher() {
  yield all([
    takeEvery(GETCLAUSEDATABYTYPE, getClauseDataByType),
    takeEvery(POSTCLAUSEDATABYTYPE, postClauseDataByType),
  ]);
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { assignOptions } from './StaticData';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import ReminderConfirmationModal from '../../../DocumentView/Component/Reminders/ReminderConfirmationModal';
import ConfirmationModal from '../../../RiverusUI/Components/ConfirmationModalComponent';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  createReminder,
  editReminder,
} from '../../../Services/DocumentLibrary';
import {
  externalUsers,
  fetchExternalUsersList,
  removeChecklistAssignment,
} from '../../../Services/Draft';
import { formatPayloadDate } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  propsData?: any;
  handleAssignee?: (payload: any) => void;
  loadingUpdateItem?: boolean;
  approversData?: any;
  isExternal?: boolean;
  optionalFieldsId?: string;
  dialogReminderData?: any;
  dialogOpened?: boolean;
  setDialogReminderData?: any;
  setReminderPayload?: any;
  optionalFields?: any;
  selectedFieldData?: any;
  draftData: any;
  allChecklists?: any[];
  updateDraftData?: (payload: any) => void;
  templateCollaborators?: any;
}

const AssignDialog: React.FC<Props> = ({
  open,
  onClose,
  propsData,
  handleAssignee,
  loadingUpdateItem,
  approversData,
  optionalFieldsId,
  dialogReminderData,
  dialogOpened,
  setDialogReminderData,
  setReminderPayload,
  optionalFields,
  selectedFieldData,
  draftData,
  allChecklists,
  updateDraftData,
  templateCollaborators,
}) => {
  const queryClient = useQueryClient();
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [reminderId, setReminderId] = useState('');
  const [showExternalFields, setShowExternalFields] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    resetField,
    formState: { isValid },
  } = methods;

  const assignee_type = watch('assignee_type') || '';
  const selectUser = watch('select_user') || '';
  const users = watch('users');
  const name = watch('name');
  const email = watch('email');
  const selectExternalUser = watch('select_external_user' || '');

  const { id } = useParams<{
    id: string;
  }>();

  // login user data
  const user_data = useMemo(() => getSessionStorage('user_profile'), []);

  //login user is owner
  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  const hasDueDate = useMemo(
    () => propsData?.reminder?.due_date || dialogReminderData?.due_date,
    [propsData, dialogReminderData]
  );

  const draftItemData = useMemo(() => {
    return optionalFields?.flatMap((field: any) =>
      field?.field_data?.filter(
        (item: any) => item?.id === selectedFieldData?.id
      )
    );
  }, [optionalFields, selectedFieldData?.id]);

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
  }, []);

  useEffect(() => {
    if (!dialogOpened) {
      setDialogReminderData(null);
    }
  }, [dialogOpened, setDialogReminderData]);

  useEffect(() => {
    if (
      dialogOpened
        ? dialogReminderData?.users?.[0]?.assignee_type === 'external'
        : propsData?.assignee_type === 'external' ||
          propsData?.user_type === 'external'
    ) {
      setValue('assignee_type', 'external');
    } else if (
      dialogOpened
        ? dialogReminderData?.users?.[0].assignee_type === 'internal'
        : propsData?.assignee_type === 'internal' ||
          propsData?.user_type === 'internal'
    ) {
      setValue('assignee_type', 'internal');
    }

    setValue(
      'due_date',
      dialogOpened
        ? dayjs(dialogReminderData?.due_date)
        : propsData?.reminder?.due_date
          ? dayjs(propsData?.reminder?.due_date)
          : ''
    );

    if (
      dialogOpened
        ? dialogReminderData?.users?.[0]?.assignee_type === 'external'
        : propsData?.assignee_type === 'external' ||
          propsData?.user_type === 'external'
    ) {
      setValue(
        'select_external_user',
        dialogOpened ? dialogReminderData?.users?.[0]?.id : propsData?.user
      );
    } else if (
      dialogOpened
        ? dialogReminderData?.users?.[0].assignee_type === 'internal'
        : propsData?.assignee_type === 'internal' ||
          propsData?.user_type === 'internal'
    ) {
      setValue(
        'select_user',
        dialogOpened ? dialogReminderData?.users?.[0]?.id : propsData?.user
      );
    }
  }, [setValue, propsData, hasDueDate, dialogReminderData, dialogOpened]);

  useEffect(() => {
    if (assignee_type === 'internal') {
      resetField('select_external_user');
    } else if (assignee_type === 'external') {
      resetField('select_user');
    }
  }, [assignee_type, resetField]);

  const getUserDetail = useMemo(
    () =>
      approversData?.find(
        (item: any) => item?.email === selectUser || item?.id === selectUser
      ),
    [approversData, selectUser]
  );

  const handleCloseDialog = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleConfirm = useCallback(() => {
    setOpenConfirmationModal(false);
    handleCloseDialog();
  }, [handleCloseDialog]);

  const { mutate: remove_assignment } = useMutation({
    mutationKey: ['remove_checklist_assignment'],
    mutationFn: removeChecklistAssignment,
    onSuccess: () => {
      enqueueSnackbar('Assignment removed successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_checkList'],
      });

      const checklistIdToRemoveFrom = propsData?.id;
      const userIdToRemove = propsData?.user;
      const remainingChecklists =
        allChecklists?.filter(
          (item: any) =>
            item?.id === propsData?.id && item?.id !== checklistIdToRemoveFrom
        ) || [];
      let filteredCollaborators;
      if (remainingChecklists?.length === 0) {
        filteredCollaborators =
          draftData?.collaborators?.filter(
            (item: any) => item?.id !== userIdToRemove
          ) || [];
      } else {
        filteredCollaborators =
          draftData?.collaborators?.map((item: any) => item?.id) || [];
      }
      const filteredCollaboratorIds = filteredCollaborators?.map(
        (collaborator: any) => collaborator?.id
      );
      const finalCollaborators = [
        ...filteredCollaboratorIds,
        ...templateCollaborators,
      ];
      const updateDraftDataPayload = {
        id: draftData?.id,
        body: {
          collaborators: Array.from(new Set(finalCollaborators)),
        },
      };
      if (updateDraftData) {
        updateDraftData(updateDraftDataPayload);
      }
      handleCloseDialog();
    },
    onError: () => {
      enqueueSnackbar('Failed to remove Assignment!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: (response: any) => {
      const remindersId = response?.data?.id;
      setReminderId(remindersId);

      enqueueSnackbar('Reminder added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      handleCloseDialog();
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleCloseDialog();
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      setOpenConfirmationModal(false);
      handleCloseDialog();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: externalUsersData } = useQuery({
    queryKey: ['external_users_list'],
    queryFn: fetchExternalUsersList,
    select: (response: any) => {
      const groups = response.results.map((data: any) => ({
        ...data,
        name: `${data.username} - ${data.email}`,
      }));
      return groups;
    },
  });

  const { mutate: externalUsersMutation } = useMutation({
    mutationKey: ['external_users'],
    mutationFn: externalUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External fields saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      setShowExternalFields(false);
      resetField('name');
      resetField('email');
    },
    onError: () => {
      enqueueSnackbar('Failed to save external fields!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const getExternalUserDetail = useMemo(
    () =>
      externalUsersData?.find(
        (item: any) =>
          item?.email === selectExternalUser || item?.id === selectExternalUser
      ),
    [externalUsersData, selectExternalUser]
  );

  const onSubmit = useCallback(
    (data: any) => {
      if (handleAssignee) {
        const payload = {
          user_name:
            data?.name ||
            getUserDetail?.name ||
            getExternalUserDetail?.first_name,
          user: data?.select_user || data?.select_external_user,
          email:
            data?.email || getUserDetail?.email || getExternalUserDetail?.email,
          assignee_type: data?.assignee_type,
          id: getUserDetail?.id || getExternalUserDetail?.id,
          ...((selectUser !== propsData?.user &&
            selectExternalUser !== propsData?.user) ||
          !('user' in propsData)
            ? { assigned_on: dayjs().format('YYYY-MM-DDTHH:mm:ssZ') }
            : {}),
        };
        handleAssignee(payload);
      }

      let reminderPayload: any = {
        checklist_name:
          propsData?.checklist_name || propsData?.data?.checklist_name,
        field_name: propsData?.field_name || draftItemData?.[0]?.field_name,
        draft: draftData?.draftID,
        due_date: data?.due_date
          ? formatPayloadDate(new Date(data?.due_date))
          : '',
        app: 'Stylus',
      };

      if (propsData?.data?.id || propsData?.checklist_type) {
        reminderPayload = {
          checklist: propsData?.id,
          reminder_type: 'checklist',
          ...reminderPayload,
        };
      } else if (propsData?.field_id) {
        reminderPayload = {
          draft_item: optionalFieldsId?.[0],
          reminder_type: 'items',
          ...reminderPayload,
        };
      }

      reminderPayload = {
        users: [
          data?.select_user ||
            dialogReminderData?.users?.[0]?.id ||
            getUserDetail?.id ||
            data?.select_external_user ||
            selectExternalUser ||
            propsData?.user,
        ],
        ...reminderPayload,
      };

      if (setReminderPayload && !propsData?.id) {
        setReminderPayload(reminderPayload);
      }

      if (hasDueDate) {
        delete reminderPayload['app'];
        delete reminderPayload['checklist_name'];
        delete reminderPayload['checklist'];
        delete reminderPayload['draft'];
        delete reminderPayload['reminder_type'];
        delete reminderPayload['field_name'];
        delete reminderPayload['draft_item'];

        const updatedReminderPayload = {
          id: dialogReminderData?.id || propsData?.reminder?.id,
          body: reminderPayload,
        };
        update_reminder(updatedReminderPayload);
      } else if (data?.due_date && propsData?.id) {
        createReminderMutation(reminderPayload);
      }
    },
    [
      getUserDetail,
      getExternalUserDetail,
      handleAssignee,
      propsData,
      draftItemData,
      id,
      dialogReminderData,
      hasDueDate,
      optionalFieldsId,
      update_reminder,
      setReminderPayload,
      createReminderMutation,
    ]
  );

  const isShowEditButton = useMemo(
    () => hasDueDate || propsData?.email,
    [hasDueDate, propsData]
  );

  const isDisabledField = useMemo(
    () => !userIsOwner || (!isEdit && isShowEditButton),
    [userIsOwner, isEdit, hasDueDate]
  );

  const handleAddNewClick = () => {
    setShowExternalFields((prev) => !prev);
  };

  const handleNewFieldsSave = () => {
    const payload = [
      {
        user_name: name,
        email: email,
      },
    ];
    externalUsersMutation(payload);
  };

  const handleRemoveAssignment = () => {
    const payload = {
      checklist: propsData?.id,
    };
    remove_assignment(payload);
  };

  const isShowRemoveAssignment = useMemo(
    () =>
      isValid &&
      userIsOwner &&
      propsData?.assignee_type &&
      propsData?.assigned_type === 'checklist' &&
      dialogReminderData?.reminder_type !== 'signature',
    [isValid, userIsOwner, propsData, dialogReminderData]
  );

  return (
    <React.Fragment>
      <CustomModal
        title={
          propsData?.checklist_type || propsData?.reminder_type === 'checklist'
            ? 'Assign this checklist item to someone'
            : dialogReminderData?.reminder_type === 'signature'
              ? 'Signature Reminder'
              : ''
        }
        open={open}
        handleClose={handleCloseDialog}
        maxWidth="md"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack width="600px" spacing={1} mt="20px">
              {(propsData?.checklist_type ||
                propsData?.reminder_type === 'checklist') && (
                <Typography fontSize="14px" color="#000">
                  Assign this checklist item to:
                </Typography>
              )}
              <RadioButtonGroup
                name="assignee_type"
                control={control}
                row
                required
                options={assignOptions}
                valueKey="value"
                disabled={
                  isDisabledField ||
                  dialogReminderData?.reminder_type === 'signature'
                }
              />
              {assignee_type === 'internal' && (
                <RISelectComponent
                  label="Select User"
                  name="select_user"
                  control={control}
                  options={approversData || draftData?.signatories}
                  required
                  valueKey="id"
                  readOnly={
                    isDisabledField ||
                    dialogReminderData?.reminder_type === 'signature'
                  }
                />
              )}
              {assignee_type === 'external' && (
                <Stack spacing={2}>
                  <Button
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      gap: '6px',
                    }}
                    onClick={handleAddNewClick}
                    disabled={isDisabledField}
                  >
                    <AddCircleOutlineRoundedIcon fontSize="small" /> Add New
                  </Button>
                  {showExternalFields && (
                    <Stack spacing={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <ControlledTextField
                          name="name"
                          control={control}
                          label="Name"
                          fullWidth
                          required
                        />
                        <ControlledTextField
                          name="email"
                          control={control}
                          label="Email"
                          fullWidth
                          type="email"
                          required
                        />
                      </Stack>
                      <Button
                        variant="outlined"
                        sx={{ width: 'fit-content', alignSelf: 'end' }}
                        onClick={handleNewFieldsSave}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}

                  <RISelectComponent
                    label="Select External User"
                    name="select_external_user"
                    control={control}
                    options={externalUsersData}
                    required
                    valueKey="id"
                    readOnly={
                      isDisabledField ||
                      dialogReminderData?.reminder_type === 'signature'
                    }
                  />
                </Stack>
              )}
              {assignee_type && (
                <Stack spacing={1}>
                  <Typography fontSize="14px" fontWeight="500" pb="10px">
                    Set a due date for the assignment:
                  </Typography>
                  <DatePickerElement
                    name="due_date"
                    label="Due Date"
                    disablePast
                    disabled={isDisabledField}
                  />
                </Stack>
              )}

              {userIsOwner && (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  marginTop="20px"
                >
                  <Stack direction="row">
                    {!isEdit && isShowEditButton && (
                      <Button
                        variant="contained"
                        onClick={() => setIsEdit(true)}
                      >
                        Edit
                      </Button>
                    )}
                    {(isEdit || !isShowEditButton) && (
                      <LoadingButton
                        type="submit"
                        loading={loadingUpdateItem}
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                    )}
                    <Button
                      variant="outlined"
                      onClick={() => setOpenConfirmationModal(true)}
                    >
                      Cancel
                    </Button>
                  </Stack>
                  {isValid && (
                    <Stack alignItems="center">
                      <Button
                        variant="text"
                        sx={{ textTransform: 'unset', height: '40px' }}
                        startIcon={<NotificationsIcon />}
                        disabled={isEdit}
                        onClick={() => {
                          if (isEdit || !hasDueDate) {
                            setOpenForm(false);
                            setErrorMessage(
                              'Save the form to send a reminder now.'
                            );
                          } else {
                            setOpenForm(true);
                          }
                        }}
                      >
                        Send a reminder now
                      </Button>
                      {errorMessage && (
                        <Typography variant="caption" color="error">
                          {errorMessage}
                        </Typography>
                      )}
                    </Stack>
                  )}
                </Stack>
              )}

              {isShowRemoveAssignment && (
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() => setOpenDeleteDialog(true)}
                  sx={{
                    padding: '5px',
                    width: 'fit-content',
                  }}
                >
                  Remove assignment
                </Button>
              )}
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to close this?"
      />
      <ReminderConfirmationModal
        open={openForm}
        handleClose={handleCloseForm}
        propsData={propsData}
        draftItemData={draftItemData}
        getUserDetail={getUserDetail}
        users={users}
        name={name}
        reminderId={reminderId}
        handleCloseDialog={handleCloseDialog}
        dialogReminderData={dialogReminderData}
      />
      {openDeleteDialog && (
        <ReusableConfirmationModal
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          title="Delete Assignment"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={handleRemoveAssignment}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected assignee will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default AssignDialog;

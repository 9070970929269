import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import SendIcon from '@mui/icons-material/Send';
import SortIcon from '@mui/icons-material/Sort';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import AddedSignatoriesList from './AddedSignatoriesList';
import AddSignatories from './AddSignatories';
import SignatoriesList from './SignatoriesList';
import SignatureMethod from './SignatureMethod';
import SignedConfirmation from './SignedConfirmation';
import StampPaper from './StampPaper';
import { signatureSequences, userTypes } from './StaticData';
import {
  getLocalStorage,
  getSessionStorage,
} from '../../../Authentication/Actions/authentication';
import { isRiverusAdmin } from '../../../DataDictionary/DDUtils';
import {
  fetchContractPreSignUrlExternal,
  fetchSignatoriesListExternal,
  getS3PresignedUrlExternal,
  sendEmailExt,
  updateSignatoriesListExternal,
  upload_file_in_s3_bucket_external,
} from '../../../ExternalUserFlow/Services/Draft';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import {
  fetchExternalUsersList,
  getS3PresignedUrl,
  send_Email,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import { fetchReminderById } from '../../../Services/Reminder';
import {
  addTempSignatories,
  deleteTempSignatories,
  fetchContractPreSignUrl,
  fetchSignatoriesList,
  fetchTempSignatories,
  updateSignatoriesList,
} from '../../../Services/signatureTab';
import {
  getAuthCredentials,
  updateWithSignRefreshToken,
} from '../../../Services/signatureTab';
import { draftStatus } from '../../State/DraftState';
import AssignDialog from '../DraftChecklist/AssignDialog';
import { applyFields, removeAnnotation } from '../Helper';

interface Props {
  draftData: any;
  instance: any;
  dropPoint: any;
  addedSignatureFields: boolean;
  setAddedSignatureFields: Dispatch<SetStateAction<boolean>>;
  updateDraftData: any;
  setRedirect?: Dispatch<SetStateAction<string>>;
  isExternal?: boolean;
  createDraft: any;
  editModeDisabled?: boolean;
  sendForAdobeSigning?: boolean;
  setContractId: Dispatch<SetStateAction<string>>;
}

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  ...draggableStyle,
});

const SignatureTab: React.FC<Props> = ({
  draftData,
  instance,
  dropPoint,
  addedSignatureFields,
  setAddedSignatureFields,
  updateDraftData,
  setRedirect,
  isExternal,
  createDraft,
  editModeDisabled,
  sendForAdobeSigning,
  setContractId,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [isSignatoryAdded, setIsSignatoryAdded] = useState<boolean>(false);
  const [signedDocument, setSignedDocument] = useState<any>();
  const [uploadedFileData, setUploadedFileData] = useState<any>();
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [signRefreshTokenData, setSignRefreshTokenData] = useState<any>();
  const [sendDraftConfirmation, setSendDraftConfirmation] =
    useState<boolean>(false);
  const [isDocumentSigned, setDocumentSigned] = useState<boolean>(false);
  const [openAssignDialog, setOpenAssignDialog] = useState<boolean>(false);
  const [dialogReminderData, setDialogReminderData] = useState<any>(null);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [openSignedConfirmation, setOpenSignedConfirmation] =
    useState<boolean>(false);
  const [isEditOrder, setIsEditOrder] = useState<boolean>(false);
  const [tempSignatories, setTempSignatories] = useState<any[]>([]);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const SparkMD5 = require('spark-md5');
  const signatories = useRef<any[]>([]);
  const XFDF = useRef<any>(null);

  const methods = useForm();
  const { control, watch, resetField, reset, setValue, handleSubmit } = methods;

  const user_type = watch('user_type') || '';
  const captured_method = watch('captured_method') || '';
  const signatory_sequence = watch('signatory_sequence') || '';

  const qs = require('querystring');

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('reminder_type');
  const reminderId = queryParams.get('reminderId') || '';
  const external_token = queryParams.get('external_token') || '';

  const signatoriesList = useMemo(
    () => draftData?.signatories || [],
    [draftData?.signatories]
  );

  const savedSignRefreshToken = getLocalStorage('signRefreshToken');

  const signRefreshToken = useMemo(
    () => savedSignRefreshToken,
    [savedSignRefreshToken]
  );

  // login user data
  const user_data = useMemo(() => getSessionStorage('user_profile'), []);

  //login user is owner
  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [draftData, user_data]
  );

  // login user is signatory
  const userIsSignatory = useMemo(
    () =>
      draftData?.signatories?.find(
        (signatory: any) => signatory?.id === user_data?.id
      ),
    [draftData?.signatories, user_data?.id]
  );

  const handleCloseAssignDialog = () => {
    if (queryParamsTypeValue === 'signature') {
      navigate(pathname);
    }
    setOpenAssignDialog(false);
    setDialogOpened(false);
  };

  const { data: reminderData } = useQuery({
    queryKey: ['get_reminder_by_id'],
    queryFn: async () => await fetchReminderById(reminderId),
    enabled: !!reminderId,
  });

  useEffect(() => {
    if (queryParamsTypeValue === 'signature' && reminderId) {
      setDialogReminderData(reminderData);
      setOpenAssignDialog(true);
      setDialogOpened(true);
    }
  }, [queryParamsTypeValue, reminderId, reminderData]);

  const handleCloseConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  // set default value
  useEffect(() => {
    if (draftData?.signature_method) {
      reset({ captured_method: draftData?.signature_method });
      setDisabled(true);
    }
  }, [reset, draftData]);

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = signedDocument;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [SparkMD5, signedDocument]
  );

  const { data: signatoriesData, isLoading } = useQuery({
    queryKey: ['signatories_list', isExternal],
    queryFn: async () => {
      const response = isExternal
        ? await fetchSignatoriesListExternal()
        : await fetchSignatoriesList();
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups;
    },
    enabled:
      !!getSessionStorage('external_auth_token') ||
      !!getLocalStorage('accessToken'),
  });

  // update api
  const { mutate: updateSignatory } = useMutation({
    mutationKey: ['update_signatory', isExternal],
    mutationFn: isExternal
      ? updateSignatoriesListExternal
      : updateSignatoriesList,
    onSuccess: (response) => {
      handleUserType();
      if (response?.data?.signature_method) {
        setDisabled(true);
      }
      queryClient.invalidateQueries({
        queryKey: ['signatories_list'],
      });
      queryClient.invalidateQueries({
        queryKey: isExternal
          ? ['get_draft_by_id_for_external']
          : ['get_draft_by_id'],
      });
      enqueueSnackbar('Signatory data updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      if (status === draftStatus?.SIGNATURE_ABORTED) {
        const payload = {
          id: draftData?.id,
          body: {
            status: status,
            link: uploadedFileData?.presigned_url?.file_path,
            version: draftData?.version,
            ...(isExternal && {
              external_token: external_token,
            }),
          },
        };
        updateDraftData(payload);
      }

      handleCloseConfirmation();
    },
    onError: () => {
      enqueueSnackbar('Failed to update signatory data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  // move draft stylus to umbrella
  const { mutate: uploadDocToUmbrella } = useMutation({
    mutationKey: ['upload_draft_document', signedDocument, isExternal],
    mutationFn: isExternal
      ? fetchContractPreSignUrlExternal
      : fetchContractPreSignUrl,
    onSuccess: (response) => {
      setUploadedFileData(response?.data);
      setContractId(response?.data?.contract?.id);
      if (response.data) {
        const reader = new FileReader();
        const file = signedDocument;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInUmbrellaS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            const stylusFile = {
              file_hash: hexHash,
              file_name: draftData?.contractName + '.pdf',
              file_size: file?.size,
              file_type: 'committed_draft',
              creation_type: isRiverusAdmin() ? 'system' : 'custom',
              type: file?.type,
            };
            setStatus(draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY);
            uploadDocument(stylusFile);
          };
        }
      }
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.errors?.[0];
      enqueueSnackbar(
        `${responseData || 'Failed to move document in umbrella!'}`,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    },
  });

  //checking all signatories has signed or not
  //if signed then move to umbrella
  const isContractExecuted = React.useCallback(() => {
    if (
      signatoriesList?.length > 0 &&
      draftData?.status.toLowerCase().includes('signature pending')
    ) {
      let totalCount = 0;
      let signedCount = 0;
      signatoriesList.forEach((data: any) => {
        totalCount += 1;
        if (data?.signed_date) {
          signedCount += 1;
        }
      });
      if (signedCount === totalCount) {
        const reader = new FileReader();
        const file = signedDocument;
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const hexHash = SparkMD5.hash(reader.result);
            const file_ = {
              file_hash: hexHash,
              file_name: draftData?.contractName + '.pdf',
              file_size: file.size,
              uploaded_type: 'Contract',
              type: file.type,
              refContract_id: file.id,
              groups: draftData?.groups,
              contract_type: [draftData?.contractType?.id],
            };
            uploadDocToUmbrella(file_);
          };
        }
      }
    }
  }, [
    SparkMD5,
    draftData,
    signatoriesList,
    signedDocument,
    uploadDocToUmbrella,
  ]);

  // update signed date
  const updateSignedDate = React.useCallback(() => {
    let signedUserIndex = -1;
    if (isExternal) {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) =>
          user?.user_type === 'external' &&
          user?.email === getLocalStorage('external_email')
      );
    } else {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) => user?.id === userIsSignatory?.id
      );
    }
    const updatedSignatoriesList = [...signatoriesList];
    updatedSignatoriesList[signedUserIndex].signed_date =
      new Date().toISOString();
    updatedSignatoriesList[signedUserIndex].status = 'Signature Signed';
    const payload = {
      id: draftData?.id,
      body: {
        signatories: updatedSignatoriesList,
        ...(isExternal && {
          external_token: external_token,
        }),
      },
    };
    updateSignatory(payload);
  }, [
    draftData?.id,
    external_token,
    isExternal,
    signatoriesList,
    updateSignatory,
    userIsSignatory?.id,
  ]);

  const updateAbortedDate = React.useCallback(() => {
    let signedUserIndex = -1;
    if (isExternal) {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) =>
          user?.user_type === 'external' &&
          user?.email === getLocalStorage('external_email')
      );
    } else {
      signedUserIndex = signatoriesList.findIndex(
        (user: any) => user?.id === userIsSignatory?.id
      );
    }
    const updatedSignatoriesList = [...signatoriesList];
    updatedSignatoriesList[signedUserIndex].declined_date =
      new Date().toISOString();
    updatedSignatoriesList[signedUserIndex].status = 'Signature Aborted';
    const payload = {
      id: draftData?.id,
      body: {
        signatories: updatedSignatoriesList,
        ...(isExternal && {
          external_token: external_token,
        }),
      },
    };
    updateSignatory(payload);
  }, [
    draftData?.id,
    external_token,
    isExternal,
    signatoriesList,
    updateSignatory,
    userIsSignatory?.id,
  ]);

  const { data: getAllTempSignatories } = useQuery({
    queryKey: ['get_all_temp_signatories'],
    queryFn: () => fetchTempSignatories(`?draft=${draftData?.id}}`),
    select: (response: any) => response?.results,
    enabled: !!draftData?.id && !isExternal,
  });

  const { mutate: uploadFileInS3Bucket, isPending: loadingS3Bucket } =
    useMutation({
      mutationKey: ['upload_draft_to_s3'],
      mutationFn: isExternal
        ? upload_file_in_s3_bucket_external
        : upload_file_in_s3_bucket,
      onSuccess: () => {
        if (status !== draftStatus?.SIGNATURE_ABORTED && !isSignatoryAdded) {
          setOpenSignedConfirmation(true);
        }
        if (
          status === draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY ||
          isSignatoryAdded
        ) {
          let tempSignatory = tempSignatories?.map((item: any) => item?.user);
          if (signatory_sequence === 'all_in_sequence') {
            tempSignatory = tempSignatory?.map(
              (signatoryData: any, index: number) => {
                return {
                  priority: index + 1,
                  ...signatoryData,
                };
              }
            );
          }
          const draftPayload = {
            version: draftData?.version + 1,
            link: uploadedFileData?.presigned_url?.file_path,
            status: status,
            version_type: draftData?.versionType,
            draftID: draftData?.draftID,
            collaborators: [],
            approvers: [],
            owners: draftData?.owners.map((data: any) => data.id),
            creator: draftData?.creator?.id ? draftData?.creator?.id : '',
            contractType: draftData?.contractType.id,
            contractName: draftData?.contractName,
            groups: draftData?.groups,
            createFrom: draftData?.createFrom,
            earlier_draft_link: draftData?.earlier_draft_link,
            executed_contract_link: draftData?.executed_contract_link,
            support_document_link: draftData?.support_document_link,
            signatory_sequence:
              signatory_sequence || draftData?.signatory_sequence,
            signatories: draftData?.signatories?.length
              ? [...draftData.signatories]
              : tempSignatory,
            signature_method: draftData?.signature_method,
          };
          createDraft(draftPayload);
          setIsSignatoryAdded(false);
        } else if (status !== draftStatus?.SIGNATURE_ABORTED) {
          updateSignedDate();
          isContractExecuted();
        } else if (status === draftStatus?.SIGNATURE_ABORTED) {
          updateAbortedDate();
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to upload document!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadFileInUmbrellaS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_umbrella_s3'],
    mutationFn: isExternal
      ? upload_file_in_s3_bucket_external
      : upload_file_in_s3_bucket,
    onSuccess: () => {
      enqueueSnackbar('Draft successfully updated in Umbrella!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document in Umbrella!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadDocument, isPending: loadingUploadFile } = useMutation({
    mutationKey: ['upload_draft_document'],
    mutationFn: isExternal ? getS3PresignedUrlExternal : getS3PresignedUrl,
    onSuccess: (response) => {
      setUploadedFileData(response?.data);
      if (response.data) {
        const file = signedDocument;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  // reset field
  const handleUserType = React.useCallback(() => {
    resetField('name');
    resetField('email');
    resetField('select_user');
    resetField('select_external_user');
  }, [resetField]);

  const { data: externalUsersData } = useQuery({
    queryKey: ['external_users_list'],
    queryFn: fetchExternalUsersList,
    select: (response: any) => {
      const groups = response.results.map((data: any) => ({
        ...data,
        name: `${data.username} - ${data.email}`,
      }));
      return groups;
    },
    enabled: !isExternal,
  });

  const addSignField = (type: string, userData: any, point?: any) => {
    if (!instance) return;
    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const fieldManager = annotationManager.getFieldManager();

    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point?.x && page?.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();
    const field = new Annotations.Forms.Field(Date.now() + 's', {
      type: 'Sig',
    });
    const signAnnot = new Annotations.FreeTextAnnotation();
    signAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    signAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      signAnnot.Width = 25.0 / zoom;
      signAnnot.Height = 200.0 / zoom;
    } else {
      signAnnot.Width = 200.0 / zoom;
      signAnnot.Height = 25.0 / zoom;
    }
    signAnnot.X = (page_point.x || page_info.width / 2) - signAnnot.Width / 2;
    signAnnot.Y = (page_point.y || page_info.height / 2) - signAnnot.Height / 2;
    signAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));

    signAnnot.custom = {
      name: `Sign here ${userData?.first_name || userData?.name}`,
    };

    // set the type of annot
    signAnnot.setContents(signAnnot.custom.name);
    signAnnot.FontSize = '' + 16.0 / zoom + 'px';
    signAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    signAnnot.TextColor = new Annotations.Color(0, 165, 228);
    signAnnot.StrokeThickness = 1;
    signAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
    signAnnot.TextAlign = 'center';

    signAnnot.Author = annotationManager.getCurrentUser();
    signAnnot.setCustomData('value', userData?.email);
    signAnnot.setCustomData('type', type);

    fieldManager.addField(field);

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(signAnnot, true);
    annotationManager.redrawAnnotation(signAnnot);
    annotationManager.selectAnnotation(signAnnot);
  };

  // call presigned url
  const updateDoc = React.useCallback(
    (blob: any, refId = true) => {
      const reader = new FileReader();
      const file = blob;
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const file_ = {
            file_hash: hexHash,
            file_name: draftData?.contractName + '.pdf',
            file_size: file.size,
            file_type: 'committed_draft',
            creation_type: isRiverusAdmin() ? 'system' : 'custom',
            type: file.type,
            ...(refId
              ? {
                  ref_draft_id: draftData.id,
                }
              : {}),
          };
          uploadDocument(file_);
        };
      }
    },
    [SparkMD5, draftData, uploadDocument]
  );

  const uploadForSigning = React.useCallback(
    async (refId = true) => {
      if (!instance) return;
      const { documentViewer, annotationManager } = instance.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations({
        widgets: true,
        fields: true,
      });
      const data = await doc.getFileData({ xfdfString });
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      setSignedDocument(blob);
      updateDoc(blob, refId);
    },
    [instance, updateDoc]
  );

  // user log in
  const isLoggedInUser = React.useCallback(
    (id: string, email?: string, user_type?: string) => {
      if (
        user_type === 'external' &&
        email === getLocalStorage('external_email') &&
        isExternal
      ) {
        return true;
      } else if (id === user_data?.id && !isExternal) {
        return true;
      }
      return false;
    },
    [isExternal, user_data?.id]
  );

  //after signing the doc successfully
  const handleConfirmation = async () => {
    try {
      if (!instance) return;
      if (isDocumentSigned) {
        let xfdfArr: any[] = [];
        const { Core } = instance;
        const { annotationManager } = Core;
        const currentXFDF = await annotationManager.exportAnnotations({
          widgets: false,
          fields: false,
        });
        XFDF.current = currentXFDF;
        xfdfArr = [...xfdfArr, currentXFDF];
        if (draftData) {
          const signatory = draftData.signatories;
          signatories.current = [...signatory];
          if (signatory.length > 0) {
            const XFDF = signatory
              .map((data: any) => data.xfdf)
              .filter((data: any) => data);
            xfdfArr = [...xfdfArr, ...XFDF];
          }
        }
        mergingAnnotations(xfdfArr);
      } else {
        const { documentViewer, annotationManager } = instance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations({
          widgets: true,
          fields: true,
        });
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        setSignedDocument(blob);
        updateDoc(blob);
      }
      setAddedSignatureFields(false);
    } catch (error) {
      console.error(error);
    }
  };

  const mergingAnnotations = async (xfdfArr: any[]) => {
    try {
      if (!instance) return;
      const { Core } = instance;
      const { documentViewer, PDFNet } = Core;
      const doc = await documentViewer.getDocument().getPDFDoc();
      doc.initSecurityHandler();
      let i;
      for (i = 0; i < xfdfArr.length; i++) {
        const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfArr[i]);
        await doc.fdfMerge(fdfDoc);
        await doc.flattenAnnotations();
      }
      const docbuf = await doc.saveMemoryBuffer(
        PDFNet.SDFDoc.SaveOptions.e_linearized
      );
      const blob = new Blob([docbuf], {
        type: 'application/pdf',
      });
      setSignedDocument(blob);
      updateDoc(blob);
    } catch (error) {
      console.error(error);
    }
  };

  const { mutate: sendEmailMutation } = useMutation({
    mutationKey: ['send_signature_aborted_email'],
    mutationFn: isExternal ? sendEmailExt : send_Email,
    onSuccess: () => {
      enqueueSnackbar('Email sent successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setStatus(draftStatus?.SIGNATURE_ABORTED);
    },
    onError: () => {
      enqueueSnackbar('Failed to send email!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const declineSignature = React.useCallback(async () => {
    const emailPayload = {
      draft: draftData?.id,
      contract_type: 'Signature Aborted',
    };
    sendEmailMutation(emailPayload);
    await removeAnnotation(instance);
    await uploadForSigning();
  }, [
    draftData?.id,
    external_token,
    instance,
    isExternal,
    updateSignatory,
    uploadForSigning,
  ]);

  const { mutate: add_temp_signatory, isPending: loadingTempSignatory } =
    useMutation({
      mutationKey: ['add_temp_signatory'],
      mutationFn: addTempSignatories,
      onSuccess: () => {
        enqueueSnackbar('Signatory added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        queryClient.invalidateQueries({
          queryKey: ['get_all_temp_signatories'],
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to add signatory!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  useEffect(() => {
    setValue('signatory_sequence', 'all');
    if (getAllTempSignatories?.length) {
      setTempSignatories(getAllTempSignatories);
    } else {
      setTempSignatories([]);
    }
  }, [getAllTempSignatories?.length]);

  const onSubmit = (data: any) => {
    const isAlreadySignatory = tempSignatories.some(
      (user: any) =>
        user?.user?.id === data.name || user?.user?.name === data.name
    );
    if (isAlreadySignatory) {
      enqueueSnackbar('This signatory is already present!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      return;
    } else {
      if (data.user_type === 'internal') {
        const internalUserData = signatoriesData.find(
          (user: any) => user.id === data.name
        );
        const tempSignatoryPayload = {
          draft: draftData?.id,
          user: {
            ...internalUserData,
            assigned_role: ['Signatories'],
            assigned_date: new Date().toLocaleDateString('en-GB'),
            captured_method: draftData?.signature_method,
            user_type: 'internal',
            version: draftData?.version + 1,
          },
        };
        add_temp_signatory(tempSignatoryPayload);
      } else {
        const externalUserData = externalUsersData.find(
          (user: any) => user?.id === data?.select_external_user
        );
        const tempSignatoryPayload = {
          draft: draftData?.id,
          user: {
            ...externalUserData,

            assigned_date: new Date().toLocaleDateString('en-GB'),
            assigned_role: ['Signatories'],
            name: externalUserData?.first_name,
            user_type: 'external',
            captured_method: draftData?.signature_method,
            version: draftData?.version + 1,
          },
        };
        add_temp_signatory(tempSignatoryPayload);
      }
    }
    resetField('user_type');
    resetField('name');
    resetField('email');
    resetField('select_user');
    resetField('select_external_user');
  };

  useQuery({
    queryKey: ['refresh_token', signRefreshTokenData, isExternal],
    queryFn: async () => await updateWithSignRefreshToken(signRefreshTokenData),
    select: (result: any) => {
      localStorage.setItem('signAccessToken', result.data.access_token);
    },
    throwOnError: (error: any) => {
      if (error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          // Invalidate tokens for starting again for refesh token
          localStorage.removeItem('signAccessToken');
          localStorage.removeItem('signRefreshToken');
        }
      }
      return false;
    },
    enabled: !!signRefreshTokenData && !isExternal,
  });

  const { data: authCredentialsData } = useQuery({
    queryKey: ['auth_credentials'],
    queryFn: async () => await getAuthCredentials(),
    enabled: !isExternal,
  });

  const credentialsClient = React.useMemo(() => {
    if (authCredentialsData) {
      const clientIdData = authCredentialsData?.data?.client_id;
      const clientSecretData = authCredentialsData?.data?.client_secret;
      const base64Key = authCredentialsData?.data?.client_key;
      const decryptData = (encryptedData: string) => {
        const key = CryptoJS.enc.Base64.parse(base64Key);
        const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
        const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
        const ciphertext = CryptoJS.lib.WordArray.create(
          dataBytes.words.slice(4)
        );
        const cipherParams = CryptoJS.lib.CipherParams.create({
          ciphertext: CryptoJS.enc.Hex.parse(ciphertext.toString()),
        });
        const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
          iv: CryptoJS.enc.Hex.parse(iv.toString()),
        });
        const decryptedText = CryptoJS.enc.Utf8.stringify(decrypted);
        return decryptedText;
      };
      return {
        id: decryptData(clientIdData),
        secret: decryptData(clientSecretData),
      };
    }
  }, [authCredentialsData]);

  useEffect(() => {
    if (
      signRefreshToken &&
      credentialsClient?.id &&
      credentialsClient?.secret
    ) {
      const data = {
        grant_type: 'refresh_token',
        client_id: credentialsClient?.id,
        client_secret: credentialsClient?.secret,
        refresh_token: signRefreshToken,
      };
      const formData = qs.stringify(data);
      setSignRefreshTokenData(formData);
    }
  }, [signRefreshToken, credentialsClient, qs]);

  const handleSendDraftToSignatory = async () => {
    if (!instance) return;
    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annotationsList = annotationManager.getAnnotationsList();
    const isNotAddedSignBox = tempSignatories?.filter((signatory: any) => {
      const index = annotationsList.findIndex((annotations: any) =>
        signatory?.user?.user_type === 'internal' ||
        signatory?.user?.user_type === 'external'
          ? annotations.getCustomData('value') === signatory?.user?.email ||
            signatory?.user?.select_external_user
          : annotations.getCustomData('value') === signatory?.user?.email
      );
      if (index === -1) {
        return signatory;
      }
    });

    if (draftData?.signature_method !== 'Adobe' && isNotAddedSignBox?.length) {
      enqueueSnackbar('Please add signatory box for all signatories!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      setOpenConfirmationModal(false);
      return;
    } else if (!signatory_sequence) {
      enqueueSnackbar('Please select signature order!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      setSendDraftConfirmation(true);
      await applyFields(instance);
      await uploadForSigning(false);
      setIsSignatoryAdded(true);
      setStatus(draftStatus?.SIGNATURE_PENDING);
      setOpenConfirmationModal(false);
    }
  };

  const { mutate: deleteAddedSignatory } = useMutation({
    mutationKey: ['delete_added_signatory'],
    mutationFn: deleteTempSignatories,
    onSuccess: () => {
      enqueueSnackbar('Signatory deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_all_temp_signatories'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete signatory!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleDeleteTempSignatory = async (tempSignatory: any) => {
    deleteAddedSignatory(tempSignatory?.id);
    await removeAnnotation(
      instance,
      tempSignatory?.user?.user_type === 'external'
        ? tempSignatory?.user?.email
        : tempSignatory?.user?.email
    );
  };

  const handleSignatureMethods = () => {
    const payload = {
      id: draftData?.id,
      body: {
        signature_method: captured_method,
      },
    };
    if (!captured_method) {
      enqueueSnackbar('Please select signature method', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      updateSignatory(payload);
    }
    return captured_method;
  };

  if (isLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedSignatories = Array.from(tempSignatories);
    const [removed] = reorderedSignatories.splice(result.source.index, 1);
    reorderedSignatories.splice(result.destination.index, 0, removed);

    // You need to update your state with the reordered list
    setTempSignatories(reorderedSignatories);
  };

  return (
    <>
      <Box
        marginY={2}
        marginX={1}
        height="100%"
        borderRadius="10px"
        sx={{ padding: '0 8px 8px' }}
      >
        {!isExternal &&
          userIsOwner &&
          !draftData?.status
            .toLowerCase()
            .includes('contract executed successfully') && (
            <StampPaper id={draftData.id} status={draftData?.status} />
          )}
        {draftData?.signatories?.length === 0 ? (
          <>
            {!isExternal &&
              userIsOwner &&
              !draftData?.status
                .toLowerCase()
                .includes('contract executed successfully') && (
                <SignatureMethod
                  control={control}
                  disabled={disabled}
                  handleSignatureMethods={handleSignatureMethods}
                  credentialsClientId={credentialsClient?.id}
                />
              )}

            {draftData?.signature_method && (
              <FormProvider {...methods}>
                <AddSignatories
                  signatoriesData={signatoriesData}
                  userTypes={userTypes}
                  user_type={user_type}
                  onSubmit={onSubmit}
                  handleUserType={handleUserType}
                  control={control}
                  handleSubmit={handleSubmit}
                  instance={instance}
                  externalUsersData={externalUsersData}
                  isLoading={loadingTempSignatory}
                />
              </FormProvider>
            )}

            {tempSignatories?.length > 0 && (
              <>
                {tempSignatories?.length > 1 && (
                  <Stack width="100%" direction="row" justifyContent="flex-end">
                    {isEditOrder ? (
                      <Button
                        variant="outlined"
                        onClick={() => setIsEditOrder(false)}
                      >
                        Save Order
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        onClick={() => setIsEditOrder(true)}
                        startIcon={<SortIcon />}
                      >
                        Edit Order
                      </Button>
                    )}
                  </Stack>
                )}

                {isEditOrder ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="signatoriesList"
                      isDropDisabled={!isEditOrder}
                    >
                      {(provided: any) => (
                        <Stack
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {tempSignatories?.map(
                            (tempSignatory: any, index: number) => (
                              <Draggable
                                key={index}
                                draggableId={String(index)}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <AddedSignatoriesList
                                      tempSignatory={tempSignatory}
                                      handleDeleteTempSignatory={
                                        handleDeleteTempSignatory
                                      }
                                      method={draftData?.signature_method}
                                      addField={addSignField}
                                      dropPoint={dropPoint}
                                      serialNumber={index + 1}
                                    />
                                  </Box>
                                )}
                              </Draggable>
                            )
                          )}
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  tempSignatories?.map((tempSignatory: any, index: number) => (
                    <AddedSignatoriesList
                      key={index}
                      tempSignatory={tempSignatory}
                      handleDeleteTempSignatory={handleDeleteTempSignatory}
                      method={draftData?.signature_method}
                      addField={addSignField}
                      dropPoint={dropPoint}
                      serialNumber={index + 1}
                    />
                  ))
                )}

                {getAllTempSignatories?.length > 1 && (
                  <Stack
                    spacing={1}
                    sx={{ backgroundColor: '#88305f1f' }}
                    borderRadius="10px"
                    padding={2}
                    marginY={2}
                  >
                    <Typography fontSize="16px" fontWeight="bold">
                      Signature Preference
                    </Typography>
                    <RadioButtonGroup
                      name="signatory_sequence"
                      control={control}
                      row
                      required
                      options={signatureSequences}
                      valueKey="value"
                    />
                  </Stack>
                )}

                {userIsOwner && (
                  <Stack alignItems="end">
                    <LoadingButton
                      loading={loadingS3Bucket || loadingUploadFile}
                      variant="contained"
                      startIcon={<SendIcon />}
                      sx={{ marginBottom: '1rem' }}
                      onClick={() => setOpenConfirmationModal(true)}
                    >
                      Send draft to signatories
                    </LoadingButton>
                  </Stack>
                )}
              </>
            )}
          </>
        ) : (
          <SignatoriesList
            signatoriesList={signatoriesList}
            isLoggedInUser={isLoggedInUser}
            addedSignatureFields={addedSignatureFields}
            draftData={draftData}
            handleConfirmation={handleConfirmation}
            setRedirect={setRedirect}
            isExternal={isExternal}
            sendDraftConfirmation={sendDraftConfirmation}
            editModeDisabled={editModeDisabled}
            instance={instance}
            setAddedSignatureFields={setAddedSignatureFields}
            setDocumentSigned={setDocumentSigned}
            declineSignature={declineSignature}
            sendForAdobeSigning={sendForAdobeSigning}
          />
        )}
      </Box>

      {openConfirmationModal && (
        <ReusableConfirmationModal
          open={openConfirmationModal}
          onClose={handleCloseConfirmation}
          onConfirm={handleSendDraftToSignatory}
          title="Send draft to signatories"
          confirmBtnText="Yes, Send to signatories"
          cancelBtnText="Cancel, Go back"
        >
          {' '}
          <Typography>
            Are you sure? You will not be able to add any more signatories or
            modify the draft further
          </Typography>
        </ReusableConfirmationModal>
      )}

      {openAssignDialog && (
        <AssignDialog
          open={openAssignDialog}
          onClose={handleCloseAssignDialog}
          dialogReminderData={dialogReminderData}
          dialogOpened={dialogOpened}
          setDialogReminderData={setDialogReminderData}
          draftData={draftData}
        />
      )}

      {openSignedConfirmation && (
        <SignedConfirmation
          open={openSignedConfirmation}
          onClose={() => setOpenSignedConfirmation(false)}
          isExternal={isExternal}
        />
      )}
    </>
  );
};

export default SignatureTab;
